import React from 'react';

import styles from '../consulting/consulting.module.scss';
import { Link } from 'gatsby'

import Layout from '../../components/layout';

import { teaching_team } from '../../images/team/';

import {
  sat,
  act,
  ssat,
  ap,
  ela,
  sat2,
} from '../../images/stock';

import {
  diploma,
  rolled,
  apple,
  notebook,
  lab,
  gear,
} from '../../images/icons';

export default () => (
  <Layout>
    <div className={styles.consultingPage}>
      <div className={styles.topSection}>
        <div className={styles.wrapper}>
          <div className={styles.contentWrapper}>
            <p className={styles.header}>A Perfect Score</p>
            <p className={styles.description}>There's no substitute for a perfect score on your college application. Whether you're preparing for the ACT, the SAT, or your AP exams, our professional educators provide the knowledge that you need to succeed on your exams.</p>
          </div>
          <div className={styles.imageWrapper}>
            <img src={teaching_team} />
          </div>
        </div>
      </div>
    
    <div className={styles.cardSection}>
      <Link to="/products/sat-prep" className={styles.card}>
        <img src={sat} />
        <div className={styles.inset}>
          <img src={rolled}/>
        </div>
        <p>SAT Preparation</p>
      </Link>
      <Link to="/products/act-prep" className={styles.card}>
        <img src={act} />
        <div className={styles.inset}>
          <img src={diploma}/>
        </div>
        <p>ACT Preparation</p>
      </Link>
      <Link to="/products/ssat-prep" className={styles.card}>
        <img src={ssat} />
        <div className={styles.inset}>
          <img src={apple}/>
        </div>
        <p>SSAT Preparation</p>
      </Link>
      <Link to="/products/english-language-arts" className={styles.card}>
        <img src={ela} />
        <div className={styles.inset}>
          <img src={notebook}/>
        </div>
        <p>English Language Arts</p>
      </Link>
      <Link to="/products/ap-enrichment" className={styles.card}>
        <img src={ap} />
        <div className={styles.inset}>
          <img src={lab}/>
        </div>
        <p>AP Enrichment</p>
      </Link>
      <Link to="/products/sat-2-prep" className={styles.card}>
        <img src={sat2} />
        <div className={styles.inset}>
          <img src={gear}/>
        </div>
        <p>SAT II Subject Tests</p>
      </Link>
    </div>
    </div>
  </Layout>
)